import { firebaseGetCountriesLeagues, firebaseGetGames } from '@/firebase/utils'
import { getCurrencyRate } from '@/services/relysia-queries'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getGamesData = createAsyncThunk(
  'sports/getGamesData',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseGetGames(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getCountriesLeagues = createAsyncThunk(
  'sports/getCountriesLeagues',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseGetCountriesLeagues(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getBSVExchangeRates = createAsyncThunk(
  'sports/getBSVExchangeRates',
  async (request, thunkAPI) => {
    try {
      const res = await getCurrencyRate()
      if (res) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const sportsSlice = createSlice({
  name: 'sports',
  initialState: {
    games: null,

    // LiveGames
    liveGames: null,
    liveLength: 0,

    // Country & Leagues
    countriesLeagues: null,
    loading: {
      games: true,
      countriesLeagues: false,
    },
    error: null,

    // BSV to USD Exchange Rates
    exchangeRates: null,

    // load Sports
    loadSports: {},

    // mobile scroll Sports
    scrollSports: {},
  },
  reducers: {
    updateLiveGamesData: (state, action) => {
      const sportsList = Object.keys(action.payload)
      let liveLength = 0
      sportsList.forEach((sport) => {
        let tempLength = 0
        const leagues = Object.values(action.payload[sport] || {})
        leagues?.forEach((league) => {
          tempLength += Number(league?.length)
        })
        liveLength += tempLength
      })
      state.liveGames = action.payload
      state.liveLength = liveLength
    },
    removeLiveGamesData: (state, action) => {
      state.liveGames = null
    },

    // ------------------ Games ------------------
    updateGamesData: (state, action) => {
      state.games = action.payload
    },

    // ------------------ Paginated Sports ------------------
    updateLoadSportsData: (state, action) => {
      state.loadSports = action.payload
    },

    // ------------------ Scroll Sports ------------------
    updateScrollSportsData: (state, action) => {
      state.scrollSports = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGamesData.fulfilled, (state, action) => {
        state.games = action.payload
        state.loading.games = false
      })
      .addCase(getGamesData.pending, (state, action) => {
        state.loading.games = true
      })
      .addCase(getGamesData.rejected, (state, action) => {
        state.error = action.payload
        state.loading.games = false
      })
      .addCase(getCountriesLeagues.fulfilled, (state, action) => {
        state.countriesLeagues = action.payload
        state.loading.countriesLeagues = false
      })
      .addCase(getCountriesLeagues.rejected, (state, action) => {
        state.error = action.payload
        state.loading.countriesLeagues = false
      })
      .addCase(getCountriesLeagues.pending, (state, action) => {
        state.loading.countriesLeagues = true
      })
      .addCase(getBSVExchangeRates.fulfilled, (state, action) => {
        state.exchangeRates = action.payload
      })
  },
})

export const {
  updateLiveGamesData,
  updateGamesData,
  removeLiveGamesData,
  updateLoadSportsData,
  updateScrollSportsData,
} = sportsSlice.actions
export default sportsSlice.reducer
