import { BETS_COLLECTION_NAME } from '@/constants/firestore'
import { firebaseDb } from '@/firebase/init'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
} from 'firebase/firestore'

const getSinglePossibleWin = ({ betPrice = 0, price = 0 }) => {
  let possibleWin = 0

  if (price > 0) {
    possibleWin = betPrice * (price / 100)
  } else {
    possibleWin = betPrice * (100 / Math.abs(price))
  }

  return isNaN(possibleWin) ? 0 : possibleWin
}

export const leaderBoardBetData = createAsyncThunk(
  'leaderBoard/leaderBoardBetData',

  async (data, thunkAPI) => {
    try {
      const querySnapshot = await getDocs(
        collection(firebaseDb, BETS_COLLECTION_NAME),
      )
      const getCollection = await getDocs(collection(firebaseDb, 'users'))
      const usersInfo = new Map()
      const usersObject = {}
      getCollection.forEach((item) => {
        usersObject[item.id] = item.data()
      })

      const allPromises = []

      querySnapshot.forEach((doc) => {
        allPromises.push(
          (async () => {
            const currentBet = doc.data()
            const currentUser = usersObject[doc.data().uid]
            const wonBetsForUserQuery = query(
              collection(firebaseDb, BETS_COLLECTION_NAME),
              where('uid', '==', doc.data().uid),
              where('grade', '==', 'win'),
            )
            const lostBetsForUserQuery = query(
              collection(firebaseDb, BETS_COLLECTION_NAME),
              where('uid', '==', doc.data().uid),
              where('grade', '==', 'loss'),
            )

            const getCount = async (queryToUse) => {
              const countQueryResult = await getDocs(queryToUse)
              return countQueryResult.size
            }

            const wonCount = await getCount(wonBetsForUserQuery)
            const lostCount = await getCount(lostBetsForUserQuery)
            if (usersInfo.has(doc.data().uid)) {
              const user = usersInfo.get(doc.data().uid)
              user.won = wonCount
              user.lose = lostCount

              if (currentBet.betType === 'multi') {
                user.totalBetsAmount += +currentBet.price
                user.bestBet = Math.max(
                  user.bestBet,
                  +currentBet.possibleWinPrice,
                )
                user.totalWonAmount += currentBet['won']
                  ? +currentBet.possibleWinPrice
                  : 0
              } else {
                const singleBetWin = getSinglePossibleWin({
                  betPrice: +currentBet.betPrice,
                  price: +currentBet.price,
                })
                user.bestBet = Math.max(user.bestBet, singleBetWin)
                user.totalWonAmount += currentBet['won'] ? singleBetWin : 0
                user.totalBetsAmount += +currentBet.betPrice
              }

              user.returnPercent =
                (Number(user.totalWonAmount) / Number(user.totalBetsAmount)) *
                100
            } else {
              let won = 0
              let lose = 0
              let bestBet = 0
              let totalWonAmount = 0
              let totalBetsAmount = 0

              if (currentBet['won']) {
                won += 1
              }

              if (!currentBet['refunded'] && !currentBet['won']) {
                lose += 1
              }

              if (currentBet.betType === 'multi') {
                totalBetsAmount += +currentBet.price
                bestBet = +currentBet.possibleWinPrice
                totalWonAmount = currentBet['won']
                  ? +currentBet.possibleWinPrice
                  : 0
              } else {
                const singleBetWin = getSinglePossibleWin({
                  betPrice: +currentBet.betPrice,
                  price: +currentBet.price,
                })
                bestBet = singleBetWin
                totalWonAmount = currentBet['won'] ? singleBetWin : 0
                totalBetsAmount += +currentBet.betPrice
              }

              usersInfo.set(doc.data().uid, {
                uid: doc.data().uid,
                player_name: currentUser?.username,
                profileUrl: currentUser?.photoPATH,
                bestBet: bestBet,
                createdAt: currentUser?.createdAt,
                won: wonCount,
                lose: lostCount,
                totalWonAmount: totalWonAmount,
                totalBetsAmount: totalBetsAmount,
                hideLeaderboard: currentUser?.hideLeaderboard || false,
                returnPercent:
                  (Number(totalWonAmount) / Number(totalBetsAmount)) * 100,
              })
            }
          })(),
        )
      })

      await Promise.all(allPromises)

      const userInfo = Array.from(usersInfo.values())
      return userInfo
        .sort((a, b) => b.totalWonAmount - a.totalWonAmount)
        .slice(0, 10)
    } catch (err) {
      console.log(err)
      thunkAPI.rejectWithValue(err.toString())
    }
  },
)

const leaderBoardSlice = createSlice({
  name: 'leaderBoard',
  initialState: {
    userDetails: [],
    leaderInfo: [],
    message: '',
    status: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(leaderBoardBetData.fulfilled, (state, { payload }) => {
        state.status = 'success'
        if (payload) {
          state.userDetails = payload
        }
      })
      .addCase(leaderBoardBetData.pending, (state, { payload }) => {
        state.status = 'pending'
      })
      .addCase(leaderBoardBetData.rejected, (state, { payload }) => {
        state.status = 'rejected'
      })
  },
})

export default leaderBoardSlice.reducer
