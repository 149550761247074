import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  getDocs,
} from 'firebase/firestore'
import { firebaseDb } from '@/firebase/init'

export class FireabseORM {
  static async readSingleDoc(collection, docId) {
    return await getDoc(doc(firebaseDb, collection, docId))
  }

  static readDoubleDoc(collection, docId, nextedCollection, nestedDoc) {
    return doc(firebaseDb, collection, docId, nextedCollection, nestedDoc)
  }

  static set(collection, docId, setObject) {
    return setDoc(doc(firebaseDb, collection, docId), setObject)
  }

  static async readCollection(collectionName) {
    const data = []
    const querySnapshot = await getDocs(collection(firebaseDb, collectionName))

    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() })
    })
    return data
  }

  static setDouble(collection, docId, nextedCollection, nestedDoc, setObject) {
    return setDoc(
      doc(firebaseDb, collection, docId, nextedCollection, nestedDoc),
      setObject,
    )
  }

  static update(collection, docId, updateObject) {
    return updateDoc(doc(firebaseDb, collection, docId), updateObject)
  }

  static updateDouble(
    collection,
    docId,
    nextedCollection,
    nestedDoc,
    updateObject,
  ) {
    return updateDoc(
      doc(firebaseDb, collection, docId, nextedCollection, nestedDoc),
      updateObject,
    )
  }

  static remove(collection, docId) {
    return deleteDoc(doc(firebaseDb, collection, docId))
  }
}
