import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  gamesRunning: 0,
}

const plinkoSlice = createSlice({
  name: 'gamePlinko',
  initialState,
  reducers: {
    setGameRunning: (state, action) => {
      state.gamesRunning = action.payload
    },
    incrementGamesRunning: (state, action) => {
      state.gamesRunning = state.gamesRunning + 1
    },
    decrementGamesRunning: (state, action) => {
      const calc = state.gamesRunning - 1 < 0 ? 0 : state.gamesRunning - 1
      state.gamesRunning = calc
    },
  },
})

export default plinkoSlice.reducer
export const { setGameRunning, incrementGamesRunning, decrementGamesRunning } =
  plinkoSlice.actions
