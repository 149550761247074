import { toast } from 'react-toastify'

export function successMsg(message) {
  toast.success(message, {
    position: 'bottom-left',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}

export function errorMsg(message, onClose) {
  toast.error(message, {
    position: 'bottom-left',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    onClose: onClose ? onClose : () => {},
  })
}

export function warnMsg(message) {
  toast.warn(message, {
    position: 'bottom-left',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  })
}
