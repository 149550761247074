export const contentTransitionStyles = {
  entering: { width: 'calc(100%-5rem)' },
  entered: { width: 'calc(100%-9rem)' },
  exiting: { width: 'calc(100%-9rem)' },
  exited: { width: 'calc(100%-5rem)' },
}

export function contentStyle(duration) {
  return { transition: `width ${duration}ms` }
}

export function sidebarStyle(duration) {
  return { transition: `width ${duration}ms` }
}

export const sidebarTransitionStyles = {
  entering: { width: '5rem' },
  entered: { width: '9rem' },
  exiting: { width: '9rem' },
  exited: { width: '5rem' },
}

export function betContentStyle(duration) {
  return { transition: `width ${duration}ms` }
}
