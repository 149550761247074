import {
  firebaseAddFavourites,
  firebaseGetFavourites,
  firebaseRemoveFavourites,
  firebaseRemoveExtrasFavourites,
} from '@/firebase/utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const addToFavourites = createAsyncThunk(
  'favourites/addToFavourites',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseAddFavourites(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getFavourites = createAsyncThunk(
  'favourites/getFavourites',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseGetFavourites(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const removeFromFavourites = createAsyncThunk(
  'favourites/removeFromFavourites',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseRemoveFavourites(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const removeExtrasFromFavourites = createAsyncThunk(
  'favourites/removeExtrasFromFavourites',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseRemoveExtrasFavourites(request)
      if (res && !res?.error) return res
      else throw res?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const initialState = {
  // Favourites
  ids: [],

  // Loader
  loading: true,
}

const favourites = createSlice({
  name: 'favourites',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addToFavourites.fulfilled, (state, action) => {
        if (!state.ids[action.payload.id]) {
          state.ids.push(action.payload.id)
        }
      })
      .addCase(removeFromFavourites.fulfilled, (state, action) => {
        const index = state.ids.indexOf(action.payload.id)
        if (index > -1) {
          state.ids.splice(index, 1)
        }
      })
      .addCase(removeExtrasFromFavourites.fulfilled, (state, action) => {
        const allIds = Object.assign({}, state.ids)

        action.payload?.favourites?.forEach((id) => {
          delete allIds[id]
        })

        state.ids = allIds
      })
      .addCase(getFavourites.fulfilled, (state, action) => {
        state.ids = action.payload.favourites
        state.loading = false
      })
      .addCase(getFavourites.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export default favourites.reducer
