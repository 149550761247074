const { Timestamp } = require('firebase/firestore')

const gamesConverter = {
  toFirestore: (game) => {
    return {
      ...game,
      start_date: Timestamp.fromDate(game.start_date),
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    return {
      ...data,
      start_date: data.start_date.toDate(),
    }
  },
}

module.exports = { gamesConverter }
