import { envMODE } from '../config/envMode'
import apiConfig from '@/config/relysiaApi'
import { SERVICE_ID } from '@/constants/environment'

let config

if (envMODE === 'DEV') {
  config = {
    apiKey: 'AIzaSyAVQIUrAcg7gV7qi8930_Z0i3Pt3mHV6Ls',
    authDomain: 'lilbit-1699.firebaseapp.com',
    databaseURL: 'https://lilbit-1699-default-rtdb.firebaseio.com',
    projectId: 'lilbit-1699',
    storageBucket: 'lilbit-1699.appspot.com',
    messagingSenderId: '247193851168',
    appId: '1:247193851168:web:1883a0ef400cada203b345',
    measurementId: 'G-5YYSBTFXPS',
  }
  // config = {
  //   apiKey: 'AIzaSyDArGQI89u_MTB5FsN5xTLE9lxm9FXhREk',
  //   authDomain: 'lilbit-98b2a.firebaseapp.com',
  //   projectId: 'lilbit-98b2a',
  //   storageBucket: 'lilbit-98b2a.appspot.com',
  //   messagingSenderId: '390695511574',
  //   appId: '1:390695511574:web:685039cd2b1a83fa5a7737',
  //   measurementId: 'G-X98KJQXXS4',
  // }
  apiConfig.defaults.headers.common['serviceId'] = SERVICE_ID
} else {
  config = {
    apiKey: 'AIzaSyAVQIUrAcg7gV7qi8930_Z0i3Pt3mHV6Ls',
    authDomain: 'lilbit-1699.firebaseapp.com',
    databaseURL: 'https://lilbit-1699-default-rtdb.firebaseio.com',
    projectId: 'lilbit-1699',
    storageBucket: 'lilbit-1699.appspot.com',
    messagingSenderId: '247193851168',
    appId: '1:247193851168:web:1883a0ef400cada203b345',
    measurementId: 'G-5YYSBTFXPS',
  }
  // config = {
  //   apiKey: 'AIzaSyDArGQI89u_MTB5FsN5xTLE9lxm9FXhREk',
  //   authDomain: 'lilbit-98b2a.firebaseapp.com',
  //   projectId: 'lilbit-98b2a',
  //   storageBucket: 'lilbit-98b2a.appspot.com',
  //   messagingSenderId: '390695511574',
  //   appId: '1:390695511574:web:685039cd2b1a83fa5a7737',
  //   measurementId: 'G-X98KJQXXS4',
  // }

  apiConfig.defaults.headers.common['serviceId'] = SERVICE_ID
}

export default config
