export const PLAYER_LIST = {
  'AJ Cunningham': '4685730',
  'AJ Dobson': '4374436',
  'AJ Fletcher': '4869424',
  'Aaron Phillips': '3098802',
  'Abdul Razak Alhassan': '3926500',
  'Abdul-Kareem Al-Selwady': '4695485',
  'Abu Azaitar': '3094635',
  'Abubakar Nurmagomedov': '3895515',
  'Abus Magomedov': '3077822',
  'Adam Fugitt': '4408375',
  'Adrian Yanez': '3998248',
  'Aiemann Zahabi': '4079149',
  'Ailin Perez': '5075333',
  Alatengheili: '3154389',
  'Albert Duraev': '3088682',
  'Aleksa Camur': '4426299',
  'Alessandro Costa': '5074121',
  'Alex Caceres': '2552906',
  'Alexa Grasso': '3136287',
  'Alexander Hernandez': '4018757',
  'Alexander Munoz': '4355664',
  'Alexander Volkanovski': '3949584',
  'Alexander Volkov': '2993650',
  'Alexandr Romanov': '4421473',
  'Alexandre Pantoja': '2560746',

  'Alex Morono': '3088232',
  'Alex Pereira': '4705658',
  'Alex Reyes': '4221609',
  'Aljamain Sterling': '3031559',
  'Allan Nascimento': '3020408',
  'Alonzo Menifield': '3948876',
  'Amanda Lemos': '4233196',
  'Amanda Nunes': '2516131',
  'Amanda Ribas': '4221863',
  'Amir Albazi': '4684848',
  'Andre Fialho': '4010740',
  'Andre Fili': '3074464',
  'Andre Lima': null,
  'Andre Muniz': '3091099',
  'Andre Petroski': '4697476',
  'Andrea Lee': '3153106',
  'Andrei Arlovski': '2354269',
  'Ange Loosa': '4875506',
  'Angel Pacheco': '4893608',
  'Angela Hill': '3115931',
  'Anshul Jubli': '5068811',
  'Anthony Hernandez': '4290956',
  'Anthony Smith': '2512976',
  'Anton Turkalj': '4875513',
  Aoriqileng: '4389085',

  'Ariane Lipski': '4227318',
  'Arman Tsarukyan': '4419372',
  'Armen Petrosyan': '4894791',
  'Arnold Allen': '3902098',
  'Ashlee Evans-Smith': '3046022',
  'Ashley Yoder': '3924442',
  'Assu Almabayev': '4294926',
  'Augusto Sakai': '3900464',
  'Austen Lane': '2223033',
  'Austin Hubbard': '4397797',
  'Austin Lingo': '4570669',
  'Azamat Murzakanov': '4227265',
  'Azat Maksum': '5141967',
  'Bassil Hafez': '4351085',
  'Batgerel Danaa': '4389093',
  'Belal Muhammad': '3172112',
  'Beneil Dariush': '3085551',
  'Benoit Saint Denis': '4895362',
  'Bill Algeo': '4076472',
  'Billy Goff': '4706975',
  'Billy Quarantillo': '3900088',
  'Blagoy Ivanov': '2556638',
  'Blake Bilder': '5080936',
  'Blood Diamond': '4899729',
  'Bobby Green': '2502364',

  'Bo Nickal': '5061870',
  'Bogdan Grad': '5144006',
  'Bogdan Guskov': '5152109',
  'Brad Katona': '4350871',
  'Brad Tavares': '2504643',
  'Brady Hiestand': '4815974',
  'Brandon Moreno': '3027545',
  'Brandon Royval': '4239928',
  'Braxton Smith': '4008506',
  'Brendan Allen': '4025699',
  'Brendon Marotte': null,
  'Brendson Ribeiro': '4379328',
  'Brogan Walker': '4024488',
  'Bruna Brasil': '4394200',
  'Brunno Ferreira': '5077131',
  'Bruno Lopes': '4691323',
  'Bruno Silva': '4333158',
  'Bryan Barberena': '3111927',
  'Bryan Battle': '4815998',
  'Bryce Mitchell': '3953381',
  'Caio Borralho': '4835137',
  'Caio Machado': '5007668',
  'Cameron Saaiman': '5077399',
  'Cameron Smotherman': '5144320',

  'Caolan Loughran': '5077789',
  'Carl Deaton': '3136321',
  'Carli Judice': '5172179',
  'Carlos Hernandez': '3249894',
  'Carlos Prates': '4294832',
  'Carlos Ulberg': '4695736',
  'Carlston Harris': '3901011',
  "Casey O'Neill": '4699589',
  'Cesar Almeida': '5144068',
  'Chad Anheliger': '4816062',
  'Chad Hanekom': '5068827',
  'Chan Sung Jung': '2501685',
  'Chandler Cole': '4358022',
  'Charalampos Grigoriou': '4878592',
  'Charles Johnson': '4375156',
  'Charles Jourdain': '4421978',
  'Charles Oliveira': '2504169',
  'Charles Radtke': '4416297',
  'Charlie Campbell': '4786358',
  'Chase Hooper': '4354421',
  'Chase Sherman': '3952937',
  'Chelsea Chandler': '4319785',
  'Chepe Mariscal': '4040926',
  'Chidi Njokuani': '2959422',
  'Chris Curtis': '2984770',

  'CJ Vergara': '4396359',
  'Chris Daukaus': '4341598',
  'Chris Duncan': '4867356',
  'Chris Gutierrez': '3961293',
  'Chris Weidman': '2553993',
  'Christian Leroy Duncan': '4848674',
  'Christian Rodriguez': '4875511',
  'Christos Giagos': '3089069',
  'Ciryl Gane': '4426000',
  'Claudio Ribeiro': '5074126',
  'Clay Guida': '2335674',
  'Clayton Carpenter': '4904998',
  'Cody Brundage': '4684474',
  'Cody Durden': '4686725',
  'Cody Garbrandt': '3163637',
  'Cody Gibson': '2591306',
  'Cody Stamann': '4046059',
  'Colby Covington': '3088810',
  'Connor Matthews': '5044438',
  'Cortavious Romious': null,
  'Cory Sandhagen': '4294504',
  'Court McGee': '2504639',
  'Cristian Quiñonez': '4884868',
  'Cub Swanson': '2354087',
  'Curtis Blaydes': '3922557',

  'Cynthia Calvillo': '4085155',
  'Da Woon Jung': '4389252',
  "Da'Mon Blackshear": '4409558',
  'Damir Ismagulov': '4215563',
  'Damon Jackson': '3099187',
  'Dan Argueta': '4815973',
  'Dan Hooker': '3109135',
  'Dan Ige': '4074001',
  'Daniel Allen': '4977643',
  'Daniel Barez': '4236496',
  'Daniel Lacerda': '4895772',
  'Daniel Marcos': '5088886',
  'Daniel Pineda': '2514828',
  'Daniel Rodriguez': '4426312',
  'Daniel Santos': '4881997',
  'Daniel Zellhuber': '4863327',
  'Danny Barlow': '5152138',
  'Danny Roberts': '2968419',
  'Danny Silva': '4702589',
  'Darren Elkins': '2504168',
  'Darrius Flowers': '4358252',
  'Davey Grant': '3070984',
  'Davi Bittencourt': null,
  'David Dvorak': '4601431',
  'David Onama': '4897850',

  'Deiveson Figueiredo': '4189320',
  'Denis Tiuliulin': '4969973',
  'Denise Gomes': '4963343',
  'Dennis Buzukja': '4686735',
  'Denys Bondar': '4713086',
  'Derek Brunson': '2560056',
  'Derrick Lewis': '2560713',
  'Devin Clark': '3971496',
  'Diana Belbita': '3977987',
  'Diego Ferreira': '3026133',
  'Diego Lopes': '4881999',
  'Dione Barbosa': '4801725',
  'Don Shainis': '4893607',
  "Don'Tale Mayes": '4249193',
  'Dooho Choi': '3093026',
  'Douglas Silva de Andrade': '3090893',
  'Drew Dober': '2951202',
  'Dricus Du Plessis': '3166126',
  'Dusko Todorovic': '4421517',
  'Dustin Jacoby': '2594871',
  'Dustin Poirier': '2506549',
  'Dylan Budka': '5032015',
  'Dylan Mantello': '4935217',
  'Ed Herman': '2335754',
  'Édgar Cháirez': '5076027',

  'Edmen Shahbazyan': '4354318',
  'Edson Barboza': '2526299',
  'Eduarda Moura': '5143888',
  'Eduardo Torres Caut': '5119536',
  'Elise Reed': '4836482',
  'Elizeu Zaleski dos Santos': '3039886',
  'Elves Brener': '5110006',
  'Emily Ducote': '4034272',
  'Erick Gonzalez': '4335947',
  'Erin Blanchfield': '4350796',
  'Ernesta Kareckaite': '5149147',
  'Eryk Anders': '4082125',
  'Esteban Ribovics': '5074131',
  'Evan Elder': '5037883',
  'Farid Basharat': '5016786',
  'Farés Ziam': '4083018',
  'Felipe dos Santos': '5143333',
  'Fernando Padilla': '4239738',
  'Fernie Garcia': '4880596',
  'Francis Marshall': '5060394',
  'Francisco Prado': '5123216',
  'Gabe Green': '4306125',
  'Gabriel Bonfim': '4921516',
  'Gabriel Miranda': '2558526',
  'Gabriella Fernandes': '4871203',

  'Gabriel Santos': '5109762',
  'Garrett Armfield': '4686270',
  'Gaston Bolaños': '4393818',
  'Gavin Tucker': '4081475',
  'Genaro Valdez': '4874988',
  'Geoff Neal': '3111997',
  'George Hardwick': '4764301',
  'Gerald Meerschaert': '2553261',
  'Giga Chikadze': '3957071',
  'Gilbert Burns': '3090197',
  'Gilbert Urbina': '4375574',
  'Gillian Robertson': '4089026',
  'Glover Teixeira': '2504929',
  'Grant Dawson': '3931156',
  'Greg Velasco': '5121866',
  'Gregory Rodrigues': '4690541',
  'Guido Cannetti': '2989857',
  'Gunnar Nelson': '2968990',
  'Guram Kutateladze': '4226626',
  'Hailey Cowan': '4374742',
  'Hakeem Dawodu': '3165010',
  'Hannah Goldy': '4289255',
  'Henry Cejudo': '3023388',
  'Holly Holm': '3028404',
  'Hyder Amil': '5027356',
  'HyunSung Park': '5068545',
  'Ian Garry': '4738092',
  'Iasmin Lucindo': '5080935',
  'Ibo Aslan': '4684240',
  'Ignacio Bahamondes': '4038116',
  'Igor da Silva': '5145496',
  'Ihor Potieria': '4873642',
  'Ikram Aliskerov': '4684776',
  'Ilia Topuria': '4350812',
  'Ilir Latifi': '2957596',
  'Ion Cutelaba': '3994033',
  'Irene Aldana': '3136286',
  'Irina Alekseeva': '4711407',
  'Isaac Dulgarian': '4918083',
  'Isis Verbeek': '4403762',
  'Islam Makhachev': '3332412',
  'Ismael Bonfim': '3093513',
  'Israel Adesanya': '4285679',
  'Issa Isakov': '4683526',
  'Istela Nunes': '4195518',
  'Ivana Petrovic': '5132151',
  'Jack Della Maddalena': '4828707',
  'Jack Jenkins': '5088885',
  'Jack Shore': '4319793',
  'Jacobi Jones': '5077056',

  'Jacob Malkoun': '4687434',
  'Jacqueline Cavalcanti': '4916251',
  'Jafel Filho': '3896934',
  'Jai Herbert': '4078246',
  'Jailton Almeida': '4873640',
  'Jair Farias': '5157670',
  'Jairzinho Rozenstruik': '4410084',
  'Jake Collier': '2988175',
  'Jake Hadley': '4705600',
  'Jake Matthews': '3089915',
  'Jalin Turner': '4339490',
  'Jamahal Hill': '4425355',
  'Jamal Pogues': '4565641',
  'Jamall Emmers': '3915502',
  'James Llontop': '5075748',
  'Jamey-Lyn Horth': '4689227',
  'Jamie Mullarkey': '4064883',
  'Jamie Pickett': '4237148',
  'Jan Blachowicz': '2506250',
  'Jaqueline Amorim': '4901883',
  'Jared Cannonier': '3154860',
  'Jared Gooden': '4375621',
  'Jared Gordon': '3894823',
  'Jarno Errens': '5093447',
  'Jasmine Jasudavicius': '4835135',

  'JJ Aldrich': '3136289',
  'Jason Knight': null,
  'Javid Basharat': '4867357',
  'Jean Matsumoto': '5149439',
  'Jean Silva': '5145766',
  'Jeka Saragih': '5070502',
  'Jennifer Maia': '3022348',
  'JeongYeong Lee': '5068724',
  'Jeremiah Wells': '3970873',
  'Jesse Butler': '4397795',
  'Jessica Andrade': '3024395',
  'Jessica Penne': '2989128',
  'Jessica-Rose Clark': '3902226',
  'Jesus Aguilar': '5074274',
  'Jhonata Diniz': '5145497',
  'Ji Yeon Kim': '3155846',
  'Jim Miller': '2335718',
  'Jimmy Crute': '4354420',
  'Jimmy Flick': '2989380',
  'Jinh Yu Frey': '3136288',
  'Jiří Procházka': '3156612',
  'Joanderson Brito': '4422355',
  'Joanne Wood': '3028064',
  'Joaquim Silva': '3897005',
  'Joaquin Buckley': '4024714',

  'Joe Pyfer': '4684135',
  'Joe Solecki': '4423876',
  'Joel Alvarez': '4411888',
  'John Castaneda': '4063869',
  'John Makdessi': '2529520',
  'Johnny Munoz': '4687003',
  'Johnny Walker': '3146944',
  'Jon Jones': '2335639',
  'Jonathan Martinez': '3928690',
  'Jonathan Pearce': '4245092',
  'Jonny Parsons': '4880114',
  'Jordan Leavitt': '4686565',
  'Jordan Wright': '4339150',
  'Jorge Masvidal': '2500857',
  'Jose Johnson': '4360028',
  'Jose Medina': '5157817',
  'Josefine Knutsson': '5007635',
  'Joselyne Edwards': '4397782',
  'Joseph Holmes': '4783385',
  'Josh Culibao': '4616087',
  'Josh Emmett': '4011299',
  'Josh Fremd': '4523403',
  'Josh Parisian': '4276994',
  'Josh Quinlan': '4788300',
  'Joshua Van': '5120301',

  'JP Buys': '4249185',
  'Josiane Nunes': '4801267',
  'Journey Newson': '4251462',
  'Juancamilo Ronderos': '4851339',
  'Julia Polastri': '4686723',
  'Julian Erosa': '3955577',
  'Julian Marquez': '4077475',
  'Juliana Miller': '4912582',
  'Julija Stoliarenko': '3157454',
  'Junior Tafa': '5122234',
  'Junyong Park': '4275043',
  'Justin Gaethje': '3022345',
  'Justin Tafa': '4566145',
  'Kai Kara-France': '4243885',
  'Kaik Brito': '5056138',
  'Kamaru Usman': '3088812',
  'Kamuela Kirk': '3939270',
  'Kanako Murata': '4078674',
  'Karine Silva': '3309918',
  'Karl Williams': '4915907',
  'Karol Rosa': '4292349',
  'Karolina Kowalkiewicz': '3102702',
  'Kasey Tanner': '5149436',
  'Kauê Fernandes': null,
  'Kaynan Kruschewsky': '4868702',

  'Kelvin Gastelum': '3026011',
  'Kennedy Nzechukwu': '4245094',
  'Ketlen Souza': '4566308',
  'Ketlen Vieira': '4039865',
  'Kevin Borjas': '5144066',
  'Kevin Holland': '3943695',
  'Kevin Jousset': '4830364',
  'Kevin Lee': '3084459',
  'Kevin Szaflarski': '5075334',
  'Kevin Vallejos': '5145681',
  'Khalil Rountree Jr.': '4028627',
  'Khamzat Chimaev': '4684751',
  'Khaos Williams': '4612580',
  'Khusein Askhabov': '4218576',
  'Kiefer Crosbie': '4425962',
  'Kleidison Rodrigues': '4875509',
  'Kron Gracie': '4032401',
  'Kurt Holobaugh': '3019246',
  'Kyle Nelson': '4408436',
  'Kyler Phillips': '4237015',
  'Kyung Ho Kang': '2504566',
  'Lando Vannata': '3949555',
  'Landon Quiñones': '4690546',
  'Lauren Murphy': '3075001',
  'Leon Edwards': '3152929',

  'Lerone Murphy': '4576101',
  'Liang Na': '4295932',
  'Lina Lansberg': '3157441',
  'Loik Radzhabov': '4419434',
  'Loma Lookboonmee': '4292347',
  'Loopy Godínez': '4700617',
  'Luan Lacerda': '4410005',
  'Luana Carolina': '4372194',
  'Luana Pinheiro': '4689229',
  'Luana Santos': '5110469',
  'Lucas Alexander': '5077398',
  'Lucas Almeida': '4863328',
  'Lucas Fernando': '5119021',
  'Lucas Rocha': '5146278',
  'Lucie Pudilova': '4040197',
  'Ludovit Klein': '4193694',
  'Luis Pajuelo': '5144312',
  'Lukasz Brzeski': '4710386',
  'Mackenzie Dern': '4021217',
  'Magomed Ankalaev': '4273399',
  'Magomed Gadzhiyasulov': '5149440',
  Maheshate: '4895360',
  'Makhmud Muradov': '4426130',
  'Makwan Amirkhani': '3162105',
  'Malcolm Gordon': '3153250',

  'Malik Lewis': '5092399',
  'Mana Martinez': '4684237',
  'Mandy Bohm': '4668069',
  'Manel Kape': '4236504',
  'Manolo Zecchini': '5157247',
  'Manon Fiorot': '4608674',
  'Manuel Torres': '4896616',
  'Marc Diakiese': '4056004',
  'Marc-Andre Barriault': '4415883',
  'Marcin Prachnio': '3998068',
  'Marcin Tybura': '3093559',
  'Marco Tulio': '5138111',
  'Marcos Rogerio de Lima': '2558075',
  'Marcus McGhee': '4895760',
  'Maria Oliveira': '4348793',
  'Marina Rodriguez': '4379258',
  'Mario Bautista': '4410868',
  'Mario Piazzon': '5149190',
  'Mark O. Madsen': '4409220',
  'Marlon Vera': '3155424',
  'Marnic Mann': '4891678',
  'Marquel Mederos': '5157668',
  'Martin Buday': '4406574',
  'Marvin Vettori': '4001851',
  'Maryna Moroz': '3281606',

  'Mateo Vogel': '5079262',
  'Mateus Mendonca': '5088891',
  'Mateusz Gamrot': '3068125',
  'Mateusz Rebecki': '4689965',
  'Matheus Nicolau': '3020090',
  'Matt Brown': '2335666',
  'Matt Frevola': '4081024',
  'Matt Schnell': '2994349',
  'Matthew Semelsberger': '4688408',
  'Mauricio Rua': '2335514',
  'Mauricio Ruffy': '5122238',
  'Max Griffin': '3040385',
  'Max Holloway': '2614933',
  'Maxim Grishin': '2558492',
  'Maycee Barber': '4246307',
  'Mayra Bueno Silva': '4371001',
  'Melissa Dixon': null,
  'Melissa Gatto': '4420893',
  'Melquizael Costa': '4425763',
  'Melsik Baghdasaryan': '4686734',
  'Merab Dvalishvili': '3948572',
  'Michael Chiesa': '2960681',
  'Michael Johnson': '2488476',
  'Michael Morales': '4869426',
  'Michal Oleksiejczuk': '4012999',

  'Michel Pereira': '4418962',
  'Michelle Waterson-Gomez': '2951504',
  'Mick Parkin': '5060505',
  'Mike Breeden': '4684468',
  'Mike Malott': '3165120',
  'Miles Johns': '4010864',
  'Miranda Maverick': '4065598',
  'Mitch Ramirez': '4816283',
  Mizuki: '3027998',
  'Modestas Bukauskas': '4279621',
  'Mohammad Yahya': null,
  'Mohammed Usman': '2312150',
  'Molly McCann': '4200455',
  'Montana De La Rosa': '4046629',
  'Montel Jackson': '4339130',
  'Montserrat Conejo Ruiz': '4399703',
  'Montserrat Rendon': '5093484',
  'Morgan Charrière': '4324622',
  'Mounir Lazzez': '4683892',
  'Movsar Evloev': '4029275',
  'Muhammad Mokaev': '4905261',
  'Muhammad Naimov': '4690540',
  'Muin Gafurov': '3960072',
  'Murtaza Talha': '5149437',
  'Muslim Salikhov': '3132513',

  'Nasrat Haqparast': '4277795',
  'Nassourdine Imavov': '4690539',
  'Natalia Silva': '4054605',
  'Nate Landwehr': '3821549',
  'Nate Maness': '4685988',
  'Nathaniel Wood': '3950354',
  'Nazim Sadykhov': '5074130',
  'Neil Magny': '3001914',
  'Nick Aguirre': '5123586',
  'Nick Fiore': '5122794',
  'Nicolas Dalby': '3026267',
  'Nikita Krylov': '3026263',
  'Niko Price': '4077451',
  'Nikolas Motta': '3897242',
  'Nora Cornolle': '4916974',
  'Norma Dumont': '4418784',
  'Nursulton Ruziboev': '4422103',
  'Nurullo Aliev': '5092398',
  'Oban Elliott': '4900808',
  "Ode' Osbourne": '4424224',
  'Omar Morales': '4372245',
  'Orion Cosce': '4687126',
  'Ottman Azaitar': '4424020',
  'Ovince Saint Preux': '2500735',
  'Paddy Pimblett': '4008549',

  'Nasrat Haqparast': '4277795',
  'Nassourdine Imavov': '4690539',
  'Natalia Silva': '4054605',
  'Nate Landwehr': '3821549',
  'Nate Maness': '4685988',
  'Nathaniel Wood': '3950354',
  'Nazim Sadykhov': '5074130',
  'Neil Magny': '3001914',
  'Nick Aguirre': '5123586',
  'Nick Fiore': '5122794',
  'Nicolas Dalby': '3026267',
  'Nikita Krylov': '3026263',
  'Niko Price': '4077451',
  'Nikolas Motta': '3897242',
  'Nora Cornolle': '4916974',
  'Norma Dumont': '4418784',
  'Nursulton Ruziboev': '4422103',
  'Nurullo Aliev': '5092398',
  'Oban Elliott': '4900808',
  "Ode' Osbourne": '4424224',
  'Omar Morales': '4372245',
  'Orion Cosce': '4687126',
  'Ottman Azaitar': '4424020',
  'Ovince Saint Preux': '2500735',
  'Paddy Pimblett': '4008549',

  'Rainn Guerrero': '5144319',
  'Ramon Taveras': '5152409',
  'Randy Brown': '3960071',
  'Rani Yahya': '2503659',
  'Raoni Barcelos': '3075570',
  'Raphael Assuncao': '2354364',
  'Raquel Pennington': '2995167',
  'Raul Rosas Jr.': '5088844',
  'Ravena Oliveira': null,
  'Reyes Cortez Jr.': '4892422',
  'Rhys McKee': '4078243',
  'Ricardo Ramos': '3571515',
  'Rickson Zenidim': '5147579',
  'Ricky Glenn': '3030256',
  'Ricky Simon': '3922491',
  'Rico DiSciullo': null,
  'Rinat Fakhretdinov': '4712980',
  'Rinya Nakamura': '5068624',
  'Rob Font': '3090451',
  'Robbie Lawler': '2431258',
  'Robbie Ring': '5147245',
  'Robert Whittaker': '3009717',
  'Rodolfo Bellato': '5060489',
  'Rodolfo Vieira': '4426250',
  'Rodrigo Nascimento': '4426192',

  'Rolando Bedoya': '5125178',
  'Roman Dolidze': '4411508',
  'Roman Kopylov': '4300149',
  'Ronnie Lawrence': '4231391',
  'Roosevelt Roberts': '4354423',
  'Rose Namajunas': '3032973',
  'Ryan Spann': '3887606',
  'Said Nurmagomedov': '3903378',
  'Saimon Oliveira': '4875505',
  'Sam Hughes': '4348673',
  'Sam Patterson': '5088916',
  'Santiago Ponzinibbio': '3043484',
  "Sean O'Malley": '4205093',
  'Sean Strickland': '3093653',
  'Sean Woodson': '4566991',
  'Sedriques Dumas': '5060467',
  'Sergei Pavlovich': '4217395',
  'Serghei Spivac': '4421246',
  'Serhiy Sidey': '5146229',
  'SeungGuk Choi': '5001016',
  'SeungWoo Choi': '4420412',
  'Shamil Abdurakhimov': '2558062',
  'Shamil Gaziev': '5145498',
  'Shane Young': '3983481',
  'Shannon Ross': '4812389',

  'Shara Magomedov': null,
  'Shauna Bannon': '5102627',
  'Shavkat Rakhmonov': '4020699',
  'Shayilan Nuerdanbieke': '4781218',
  'Sodiq Yusuff': '4289274',
  'Song Kenan': '4284870',
  'Song Yadong': '3151289',
  'Stephanie Egger': '4065597',
  'Stephen Thompson': '2615077',
  'Steve Erceg': '4997217',
  'Steve Garcia': '3023804',
  'Steven Nguyen': '4426305',
  'Steven Peterson': '3041138',
  'Stipe Miocic': '2504951',
  'Tabatha Ricci': '4868095',
  'Tafon Nchukwi': '4600143',
  'Tai Tuivasa': '3722422',
  'Taila Santos': '3170347',
  'Tainara Lisboa': '4418809',
  'Takashi Sato': '4355731',
  'Talita Alencar': '5139556',
  'Tamires Vidal': '4434010',
  'Tanner Boser': '4232775',
  'Tatiana Suarez': '4020435',
  'Tatsuro Taira': '4917772',

  'TJ Brown': '4063667',
  'Taylor Lapilus': '3151934',
  'Tereza Bleda': '5056144',
  'Terrance McKinney': '4425604',
  'Terrence Mitchell': '5148677',
  'Themba Gorimbo': '4963269',
  'Thiago Moises': '3955778',
  'Thomas Petersen': '4869702',
  'Tim Elliott': '2614020',
  'Tim Means': '2500946',
  'Timothy Cuamba': '5123311',
  'Timur Valiev': '3112007',
  'Tom Aspinall': '4010976',
  'Tom Nolan': '5144007',
  'Tony Ferguson': '2560085',
  'Tony Gravely': '4084453',
  'Torrez Finney': '4050996',
  'Toshiomi Kazama': '5068617',
  'Tracy Cortez': '4243624',
  'Trevin Giles': '4024732',
  'Trevin Jones': '4012408',
  'Trevor Peek': '5048900',
  'Trey Ogden': '4294378',
  'Trey Waters': '5092403',
  'Tucker Lutz': '4684678',

  'Tyson Nam': '2976026',
  'Tyson Pedro': '4071969',
  'Umar Nurmagomedov': '4569549',
  'Uros Medic': '4685870',
  'Val Woodburn': '4926991',
  'Valentina Shevchenko': '2554705',
  'Vanessa Demopoulos': '4683395',
  'Veronica Hardy': '4044708',
  'Viacheslav Borshchev': '4877915',
  'Vicente Luque': '3045887',
  'Victor Altamirano': '4713584',
  'Victor Dias': '4788298',
  'Victor Henry': '3114234',
  'Victor Hugo': '4293183',
  'Victor Madrigal': '4688624',
  'Victor Martinez': '4252258',
  'Victoria Leonardo': '4374740',
  'Viktoriia Dudakova': '5060398',
  'Vinc Pichel': '2960679',
  'Vinicius Oliveira': '4884877',
  'Vinicius Salvador': '4869425',
  'Virna Jandiroba': '4289516',
  'Vitor Petrino': '5060483',
  'Viviane Araujo': '4379362',
  'Volkan Oezdemir': '3110836',

  'Waldo Cortes-Acosta': '4903365',
  'Warlley Alves': '3108775',
  'Wellington Turman': '4426282',
  'Westin Wilson': '4305514',
  'William Gomis': '4324623',
  'William Knight': '4255766',
  'Yair Rodriguez': '3155420',
  'Yan Xiaonan': '4275487',
  'Yana Santos': '3154898',
  'Yanal Ashmouz': '4917145',
  'Yanis Ghemmouri': '5152931',
  'Yazmin Jauregui': '5063403',
  Yizha: '4894925',
  'Yohan Lainesse': '4880807',
  'Yousri Belgaroui': '5149815',
  'Yuri Panferov': '5147207',
  'Yusaku Kinoshita': '5085249',
  'Zac Pauga': '4896541',
  'Zachary Reese': '5143223',
  'Zak Cummings': '2512055',
  'Zarah Fairn': '4078218',
  'Zhalgas Zhumagulov': '4321051',
  'Zhang Weili': '4350762',
  'Zubaira Tukhugov': '3083644',
}
