import { getLeagueByName } from '@/constants/countries-leagues'
import { firebaseGetSideBarData } from '@/firebase/utils'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

export const addSidebarItem = (request) => async (dispatch) => {
  try {
    const sports = request ? Object.keys(request) : []
    const leagues = {}
    sports.forEach((sport) => {
      const allLeagues = Object.keys(request[sport])
      const leaguesArr = []
      allLeagues?.forEach((leagueKey) => {
        const singleMatch = request[sport][leagueKey][0]
        leaguesArr.push(getLeagueByName(singleMatch?.league))
      })

      leaguesArr?.sort((a, b) => a.index - b.index)
      leagues[sport] = leaguesArr
    })
    const res = {
      sports: sports?.sort(),
      leagues: leagues,
    }
    if (res && !res?.error) return dispatch(setSidebarData(res))
    else throw res?.error
  } catch (error) {
    // console.log(error)
  }
}

export const setSidebarItems = (data) => async (dispatch, getState) => {
  try {
    const previosData = Object.assign({}, getState().sports.games) || {}
    const sports = Object.keys(data) || {}
    sports.forEach((sport) => {
      previosData[sport] = data[sport]
    })

    return dispatch(addSidebarItem(previosData))
  } catch (error) {
    // console.log(error)
  }
}

export const getSideBarData = createAsyncThunk(
  'sports/getSidebarData',
  async (request, thunkAPI) => {
    try {
      const res = await firebaseGetSideBarData(request)
      if (res) {
        const sports = res ? Object.keys(res) : []
        const leagues = {}
        sports.forEach((sport) => {
          const allLeagues = Object.keys(res[sport])
          const leaguesArr = []
          allLeagues?.forEach((leagueKey) => {
            const singleMatch = res[sport][leagueKey]
            leaguesArr.push(getLeagueByName(singleMatch))
          })
          leaguesArr?.sort((a, b) => a.index - b.index)
          leagues[sport] = leaguesArr
        })
        const resp = {
          sports: sports?.sort(),
          leagues: leagues,
        }
        return resp
      } else throw res.error
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const getInitalVisitedLeagues = () => {
  let leagues = Cookies.get('visited-leagues')
  if (leagues) {
    leagues = JSON.parse(leagues)
    return leagues
  }
  return {
    mlb: 4,
    nba: 3,
    nhl: 2,
  }
}

const updateVisitedLeaguesInCookies = (visitedLeague) => {
  const prevLeagues = Object.assign({}, getInitalVisitedLeagues())

  prevLeagues[visitedLeague?.toLowerCase()] =
    Number(prevLeagues[visitedLeague?.toLowerCase()] || 0) + 1
  setVisitedLeaguesInCookies(prevLeagues)
  return prevLeagues
}

const setVisitedLeaguesInCookies = (newData) => {
  Cookies.remove('visited-leagues')
  Cookies.set('visited-leagues', JSON.stringify(newData))
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    isOpen: false,
    activeRoute: '',
    bottomTabLabel: 'Casino',
    sports: {},
    visitedLeagues: getInitalVisitedLeagues(),
    leagues: null,
    activeSport: '',
    activeLeague: '',
    loading: false,
    chat: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isOpen = !state.isOpen
    },
    toggleChatBar: (state) => {
      state.chat = !state.chat
    },
    setActiveRoute: (state, action) => {
      state.activeRoute = action.payload
      state.loading = true
    },
    setActiveSport: (state, action) => {
      state.activeSport = action.payload
      state.activeLeague =
        (state.leagues &&
          state.leagues[action.payload] &&
          state.leagues[action.payload][0]?.name) ||
        ''
      state.loading = true
    },
    setSecActiveSport: (state, action) => {
      state.activeSport = action.payload
      // console.log('This One??')
      // state.activeLeague =
      //   (state.leagues &&
      //     state.leagues[action.payload] &&
      //     state.leagues[action.payload][0]?.name) ||
      //   ''
    },
    setActiveLeague: (state, action) => {
      state.activeLeague = action.payload
      state.visitedLeagues = updateVisitedLeaguesInCookies(action.payload)
      // state.loading = true
    },
    updateVisitedIndex: (state, action) => {
      state.visitedLeagues = updateVisitedLeaguesInCookies(action.payload)
    },
    setManuallyActive: (state, action) => {
      state.activeSport = action.payload.sport || ''
      state.activeLeague = action.payload.league || ''
      // state.loading = true
    },
    setSidebarData: (state, action) => {
      state.sports = action.payload.sports
      state.leagues = action.payload.leagues
    },
    resetRoute: (state) => {
      state.activeSport = ''
      state.activeLeague = ''
    },
    setSidebarLoading: (state, action) => {
      state.loading = action.payload || false
    },
    setBottomTabLabel: (state, action) => {
      state.bottomTabLabel = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSideBarData.fulfilled, (state, action) => {
        state.sports = action.payload.sports
        state.leagues = action.payload.leagues
        state.loading = false
      })
      .addCase(getSideBarData.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(getSideBarData.pending, (state, action) => {
        state.loading = true
      })
  },
})

export default sidebarSlice.reducer
export const {
  toggleSidebar,
  setActiveRoute,
  setActiveSport,
  setSecActiveSport,
  setActiveLeague,
  setSidebarData,
  setManuallyActive,
  updateVisitedIndex,
  resetRoute,
  setBottomTabLabel,
  setSidebarLoading,
  toggleChatBar,
} = sidebarSlice.actions
