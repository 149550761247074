import React, { memo } from 'react'
import { useRouter } from 'next/router'
import NextImage from '@/utils/Image'
import { useSelector } from 'react-redux'
import authSelector from '@/redux/selectors/auth'
import { FormattedMessage } from 'react-intl'

function HeaderButton({
  icon,
  className,
  active,
  centered,
  label,
  defaultLabel,
  prefetch = false,
  href = '#',
  page,
  imgHeight,
  imgWidth,
  header,
  bottom,
  registerClass,
  loginClass,
  exploreClass,
  home,
  ...rest
}) {
  const router = useRouter()
  const { user } = useSelector(authSelector)
  const isAdmin = user?.roleType === 'admin'

  const onChange = () => {
    // const parentRoute = router.pathname.split('/')[1]
    // if (href.includes(parentRoute)) {
    //   return
    // }
    // router.push(href)
    const currentPath = router.pathname
    if (href === currentPath) {
      return
    }
    router.push(href)
  }

  const handleOnClick = () => {
    if (href === '#') return rest?.onClick
    router.push(href)
  }
  return (
    <>
      {isAdmin ? (
        <div>
          {label !== 'Bet Slip' && (
            <button
              className={`flex items-center justify-center space-x-2 text-white px-3 py-2 rounded-lg cursor-pointer  ${
                registerClass
                  ? 'flex flex-row-reverse justify-center  gap-x-2 bg-[#008000] w-full sm:w-auto'
                  : loginClass
                  ? 'flex flex-row-reverse justify-center gap-x-2 bg-[#FFFFFF]/10  w-full sm:w-auto'
                  : exploreClass
                  ? 'flex justify-center mt-2 flex-row-reverse gap-x-2 bg-[#008000]'
                  : ''
              }
      ${active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'}
      
      ${
        router.pathname.includes(page)
          ? `bg-[#fff]/10 ${className}`
          : 'hover:bg-[#fff]/10'
      }
        ${centered ? 'justify-center' : ''}
        `}
              onClick={header ? onChange : handleOnClick}
              {...rest}
            >
              {bottom ? (
                <>
                  <div className="flex flex-col justify-center items-center ">
                    <NextImage
                      src={icon}
                      height={imgHeight ? imgHeight : 24}
                      width={imgWidth ? imgWidth : 25}
                      alt="next"
                      // style={{ objectFit: 'cover', width: 'auto', height: 'auto' }}
                      sizes="100vw"
                    />

                    <span className="responsive-text text-white font-medium font-inter pt-1 ">
                      <FormattedMessage
                        id={label}
                        defaultMessage={defaultLabel}
                      />
                      {/* {label} */}
                    </span>
                  </div>
                </>
              ) : (
                !home && (
                  <>
                    <NextImage
                      src={icon}
                      height={imgHeight ? imgHeight : 24}
                      width={imgWidth ? imgWidth : 25}
                      alt="next"
                      // style={{ objectFit: 'cover', width: 'auto', height: 'auto' }}
                      sizes="100vw"
                    />
                    <span
                      className={`${
                        registerClass || loginClass
                          ? 'text-lg'
                          : exploreClass
                          ? 'text-sm'
                          : 'text-base'
                      }  font-medium font-inter whitespace-nowrap`}
                    >
                      <FormattedMessage
                        id={label}
                        defaultMessage={defaultLabel}
                      />
                      {/* {label} */}
                    </span>
                  </>
                )
              )}
              {/* {!home && (
        <NextImage
          src={icon}
          height={imgHeight ? imgHeight : 24}
          width={imgWidth ? imgWidth : 25}
          alt="next"
          style={{ objectFit: 'cover', width: 'auto', height: 'auto' }}
          sizes="100vw"
        />
      )} */}

              {/* <span
        className={`${
          registerClass || loginClass
            ? 'text-lg'
            : exploreClass
            ? 'text-sm'
            : 'text-base'
        }  font-medium font-inter whitespace-nowrap`}
      >
        {label}
      </span> */}
            </button>
          )}
        </div>
      ) : (
        <button
          className={`flex items-center justify-center space-x-2 text-white px-3 py-2 rounded-lg cursor-pointer  ${
            registerClass
              ? 'flex flex-row-reverse justify-center  gap-x-2 bg-[#008000] w-full sm:w-auto'
              : loginClass
              ? 'flex flex-row-reverse justify-center gap-x-2 bg-[#FFFFFF]/10  w-full sm:w-auto'
              : exploreClass
              ? 'flex justify-center mt-2 flex-row-reverse gap-x-2 bg-[#008000]'
              : ''
          }
${active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'}

${
  router.pathname.includes(page)
    ? `bg-[#fff]/10 ${className}`
    : 'hover:bg-[#fff]/10'
}
${centered ? 'justify-center' : ''}
`}
          onClick={header ? onChange : handleOnClick}
          {...rest}
        >
          {bottom ? (
            <>
              <div className="flex flex-col justify-center items-center ">
                <NextImage
                  src={icon}
                  height={imgHeight ? imgHeight : 24}
                  width={imgWidth ? imgWidth : 25}
                  alt="next"
                  sizes="100vw"
                />

                <span className="responsive-text text-white font-medium font-inter pt-1 ">
                  <FormattedMessage id={label} defaultMessage={defaultLabel} />
                  {/* {label} */}
                </span>
              </div>
            </>
          ) : (
            !home && (
              <>
                <NextImage
                  src={icon}
                  height={imgHeight ? imgHeight : 24}
                  width={imgWidth ? imgWidth : 25}
                  alt="next"
                  sizes="100vw"
                />
                <span
                  className={`${
                    registerClass || loginClass
                      ? 'text-lg'
                      : exploreClass
                      ? 'text-sm'
                      : 'text-base'
                  }  font-medium font-inter whitespace-nowrap`}
                >
                  <FormattedMessage id={label} defaultMessage={defaultLabel} />
                  {/* {label} */}
                </span>
              </>
            )
          )}
        </button>
      )}
    </>
  )
}
export default memo(HeaderButton)
