import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  // Bet
  price: '0.00',
  betType: 'single',
  pastBet: false,
  acceptAnyOdds: true,

  multibetNote: '',

  allBetsLoader: true,

  notesModel: {
    id: '',
    forMulti: false,
  },
}

const betsSlice = createSlice({
  name: 'bets',
  initialState,
  reducers: {
    changeBetType: (state, action) => {
      state.betType = action.payload
    },
    changePastBet: (state, action) => {
      state.pastBet = action.payload
    },

    toggleAcceptAnyOdds: (state) => {
      state.acceptAnyOdds = !state.acceptAnyOdds
    },
    updateMultiBetPrice: (state, action) => {
      state.price = action.payload
    },
    resetBets: (state, action) => {
      state.price = '0.00'
      state.betType = 'single'
      state.acceptAnyOdds = true
      state.multibetNote = ''
    },
    betsLoader: (state, action) => {
      state.allBetsLoader = action.payload || false
    },
    addNoteToMulti: (state, action) => {
      state.multibetNote = action.payload || ''
    },
    closeNotesModel: (state) => {
      state.notesModel = {
        id: '',
        forMulti: false,
      }
    },

    openNotesModel: (state, action) => {
      if (action.payload) {
        state.notesModel = {
          id: action.payload,
          forMulti: false,
        }
      } else {
        state.notesModel = {
          id: '',
          forMulti: true,
        }
      }
    },
  },
})

export default betsSlice.reducer
export const {
  changeBetType,
  changePastBet,
  toggleAcceptAnyOdds,
  updateMultiBetPrice,
  addNoteToMulti,
  resetBets,
  betsLoader,
  openNotesModel,
  closeNotesModel,
} = betsSlice.actions
