import HeaderButton from '@/components/button/HeaderButton'
import Link from 'next/link'
import { useRouter } from 'next/router'
import NextImage from '@/utils/Image'
import { useMediaQuery } from 'react-responsive'

const navigation = [
  {
    label: 'Casino',
    icon: (props) => (
      <div {...props}>
        <NextImage
          src="/img/casino-header.webp"
          height={24}
          width={25}
          alt="next"
        />
      </div>
    ),
    href: '/plinko',
    page: 'plinko',
  },
  {
    label: 'Sports',
    icon: (props) => (
      <div {...props}>
        <NextImage
          src="/img/games-header.webp"
          alt="next"
          height={24}
          width={25}
        />
      </div>
    ),
    href: '/sports/popular-events',
    page: 'sports',
  },
  {
    label: 'Leaderboard',
    icon: (props) => (
      <div {...props}>
        <NextImage
          src="/img/leaderboard-header.webp"
          alt="next"
          height={24}
          width={25}
        />
      </div>
    ),
    href: '/leaderboard',
    page: 'leaderboard',
  },
]

const HeaderIconButton = ({ icon: Icon, active, ...rest }) => {
  return (
    <div
      className={`text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center  ${
        active ? 'bg-[#fff]/10' : 'hover:bg-[#fff]/10'
      }`}
      {...rest}
    >
      <Icon className="w-5 h-5 " />
    </div>
  )
}

export default function Header({ isSidebarOpen }) {
  const lg = useMediaQuery({ query: '(min-width: 991px)' })
  const router = useRouter()

  return (
    <>
      <div className="w-full mx-auto flex items-center justify-between px-8 py-4 flex-wrap md:flex-nowrap gap-2">
        <div className={`items-center gap-2 ${lg ? 'flex' : 'hidden'}`}>
          {!isSidebarOpen && (
            <Link href="/home">
              <NextImage
                src="/img/lil-bit-logo.svg"
                alt="logo"
                className="mr-8 cursor-pointer"
                width={105}
                height={105}
              />
            </Link>
          )}
          {navigation.map((item, index) => (
            <HeaderButton key={index} {...item} header />
          ))}
        </div>

        <div className="items-center gap-2 flex flex-wrap md:flex-nowrap">
          <HeaderIconButton
            icon={(props) => (
              <div {...props}>
                <NextImage
                  src="/img/wallet.webp"
                  width={20}
                  height={20}
                  alt="wallet"
                />
              </div>
            )}
            onClick={() => router.push('/settings?tab=wallet')}
          />
        </div>
      </div>
    </>
  )
}
