import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit'
import blackjack from '@/components/pages-partials/black-jack/lib/index'

const { BUST, WIN, LOSE, PUSH, BLACKJACK } = blackjack.results

const BASE_HAND = {
  cards: [],
  result: undefined,
  bets: [],
}

const DEFAULT_DELAY = 500
const DEALER_DECISION_DELAY = 750

const clone = (obj) => JSON.parse(JSON.stringify(obj))

const initialState = {
  isTitleShowing: true,
  settings: {
    deckCount: 6,
    startingBank: 25,
    shuffleAfterPercent: 0.75,
    minimumBet: 1,
    showBasicStrategy: false,
  },
  bank: 0,
  shoe: [],
  hands: [],
  activeHandIndex: null,
  isDealing: false,
  showDrawer: false,
}

export const startNewGame = createAsyncThunk(
  'blackjack/startNewGame',
  async (_, { getState, dispatch }) => {
    const state = getState().blackjack
    if (state.hands.lenght > 0 && state.hands.some((hand) => hand.bets[0]))
      return
    dispatch(resetRound())
    dispatch(blackjackSlice.actions.hideTitleScreen())
    dispatch(blackjackSlice.actions.resetShoe())
    dispatch(blackjackSlice.actions.resetBank())
  },
)

export const resetRound = createAsyncThunk(
  'blackjack/resetRound',
  async (_, { dispatch }) => {
    dispatch(blackjackSlice.actions.resetHands())
    dispatch(reshuffleIfNeeded())
    dispatch(blackjackSlice.actions.bet())
    dispatch(dealRound())
  },
)

export const reshuffleIfNeeded = createAsyncThunk(
  'blackjack/reshuffleIfNeeded',
  async (_, { getState, dispatch }) => {
    const state = getState().blackjack
    const shoeUsedPercent =
      1 - state.shoe.length / (state.settings.deckCount * 52)
    if (shoeUsedPercent >= state.settings.shuffleAfterPercent) {
      dispatch(blackjackSlice.actions.resetShoe())
    }
  },
)

export const dealRound = createAsyncThunk(
  'blackjack/dealRound',
  async (_, { getState, dispatch }) => {
    const state = getState().blackjack
    console.log(state.hands[1].bets[0])
    if (!state.hands[1].bets[0]) return // must have a bet to deal round
    const dealQueue = [1, 0, 1, 0] // player, dealer, player, dealer
    console.log('dealROundddddd')
    dealQueue.forEach((handIndex, i) => {
      setTimeout(() => {
        dispatch(blackjackSlice.actions.deal({ handIndex }))
      }, DEFAULT_DELAY * (i + 1))
    })
    setTimeout(() => {
      dispatch(startRound())
    }, DEFAULT_DELAY * 5)
  },
)

export const startRound = createAsyncThunk(
  'blackjack/startRound',
  async (_, { getState, dispatch }) => {
    const state = getState().blackjack
    dispatch(blackjackSlice.actions.checkForBustsAndBlackjacks())
    if (state.hands.find((hand) => hand.result)) {
      dispatch(blackjackSlice.actions.revealDealerHand())
      dispatch(endRound())
    } else {
      dispatch(startNextTurn())
    }
  },
)

export const startNextTurn = createAsyncThunk(
  'blackjack/startNextTurn',
  async (_, { getState, dispatch }) => {
    const advanceActiveHandPromise = new Promise((resolve, reject) => {
      try {
        dispatch(blackjackSlice.actions.advanceActiveHand())
        resolve()
      } catch (error) {
        reject(error)
      }
    })

    advanceActiveHandPromise
      .then(() => {
        const state = getState().blackjack
        console.log('activeHandIndex', state.activeHandIndex)
        console.log(selectActiveHand(state)?.cards?.length)

        if (selectActiveHand(state)?.cards?.length === 1) {
          console.log('startNextTurn first if')
          // A newly split hand
          let onlyOnce = selectActiveHand(state).cards[0].value === 'A'
          setTimeout(() => {
            dispatch(hit({ onlyOnce }))
          }, DEFAULT_DELAY)
        }

        if (state.activeHandIndex === 0) {
          console.log('startNextTurn second if')
          setTimeout(() => {
            dispatch(blackjackSlice.actions.revealDealerHand())
            setTimeout(() => {
              dispatch(makeDealerDecision())
            }, DEALER_DECISION_DELAY)
          }, DEFAULT_DELAY)
        }
      })
      .catch((error) => {
        console.error('Error advancing active hand:', error)
        // Handle errors, if any
      })
  },
)

export const makeDealerDecision = createAsyncThunk(
  'blackjack/makeDealerDecision',
  async (_, { getState, dispatch }) => {
    const state = getState().blackjack
    const remainingHands = state.hands.find((hand, i) => !hand.result && i > 0)
    if (selectDealerTotal(state) < 17 && remainingHands) {
      console.log('makeDealerDecision - hit')
      dispatch(hit({ onlyOnce: false, isDealer: true }))
    } else {
      console.log('makeDealerDecision - stand')
      dispatch(stand())
    }
  },
)

export const hit = createAsyncThunk(
  'blackjack/hit',
  async ({ onlyOnce = false, isDealer = false }, { dispatch, getState }) => {
    const state = getState().blackjack
    dispatch(blackjackSlice.actions.setIsDealing({ isDealing: true }))
    dispatch(
      blackjackSlice.actions.deal({
        handIndex: state.activeHandIndex,
      }),
    )
    dispatch(blackjackSlice.actions.checkForBustsAndBlackjacks())
    setTimeout(() => {
      const state = getState().blackjack
      dispatch(blackjackSlice.actions.setIsDealing({ isDealing: false }))
      if (selectActiveHand(state)?.result || onlyOnce)
        return dispatch(endTurn())
      if (blackjack.score(selectActiveHand(state)?.cards) === 21)
        return dispatch(endTurn())
      if (isDealer) dispatch(makeDealerDecision())
    }, DEFAULT_DELAY)
  },
)

export const doubleDown = createAsyncThunk(
  'blackjack/doubleDown',
  async (_, { dispatch }) => {
    dispatch(blackjackSlice.actions.setIsDealing({ isDealing: true }))
    dispatch(blackjackSlice.actions.doubleBet())
    setTimeout(() => {
      dispatch(hit({ onlyOnce: true }))
    }, DEFAULT_DELAY)
  },
)

export const splitAction = createAsyncThunk(
  'blackjack/splitAction',
  async (_, { dispatch }) => {
    dispatch(blackjackSlice.actions.split())
    dispatch(blackjackSlice.actions.resetActiveHand())
    setTimeout(() => {
      dispatch(startNextTurn())
    }, DEFAULT_DELAY * 2)
  },
)

export const stand = createAsyncThunk(
  'blackjack/stand',
  async (_, { dispatch }) => {
    dispatch(endTurn())
  },
)

export const endTurn = createAsyncThunk(
  'blackjack/endTurn',
  async (_, { dispatch, getState }) => {
    const state = getState().blackjack
    if (state.activeHandIndex > 0) {
      console.log('as')
      dispatch(startNextTurn())
    } else {
      console.log('sa')
      dispatch(endRound())
    }
  },
)

export const endRound = createAsyncThunk(
  'blackjack/endRound',
  async (_, { dispatch }) => {
    console.log('endRound')
    dispatch(blackjackSlice.actions.resetActiveHand())
    dispatch(blackjackSlice.actions.compareHands())
    setTimeout(() => {
      dispatch(blackjackSlice.actions.settleHands())
    }, DEFAULT_DELAY * 1.5)
    setTimeout(() => {
      dispatch(blackjackSlice.actions.collectWinnings())
    }, DEFAULT_DELAY * 3.5)
    setTimeout(() => {
      dispatch(resetRound())
    }, DEFAULT_DELAY * 4)
  },
)

export const restart = createAsyncThunk(
  'blackjack/restart',
  async (_, { dispatch }) => {
    dispatch(startNewGame())
  },
)

const blackjackSlice = createSlice({
  name: 'blackjack',
  initialState,
  reducers: {
    hideTitleScreen: (state) => {
      state.isTitleShowing = false
    },
    toggleDrawer: (state, { payload: { show } }) => {
      state.showDrawer = show === undefined ? !state.showDrawer : show
    },
    toggleBasicStrategy: (state) => {
      state.settings.showBasicStrategy = !state.settings.showBasicStrategy
    },
    resetShoe: (state) => {
      const createdShoe = blackjack.createShoe(state.settings.deckCount)
      state.shoe = blackjack.shuffle(createdShoe)
    },
    resetBank: (state) => {
      state.bank = state.settings.startingBank
    },
    resetHands: (state) => {
      console.log('resetHands')
      state.hands = [clone(BASE_HAND), clone(BASE_HAND)]
    },
    bet: (state) => {
      if (state.settings.startingBank < state.settings.minimumBet) return
      state.bank -= state.settings.minimumBet
      const bets = [state.settings.minimumBet]
      console.log(bets, 'betsssssssssss')
      state.hands[1].bets = bets.slice()
    },
    doubleBet: (state) => {
      const bets = state.hands[state.activeHandIndex].bets
      state.bank = state.bank - bets[0]
      bets[1] = bets[0]
      state.hands[state.activeHandIndex].bets = bets.slice()
    },
    deal: (state, { payload: { handIndex } }) => {
      const hand = state.hands[handIndex]
      let newCard = state.shoe.shift()
      const isFirstDealerCard = handIndex === 0 && hand.cards.length === 0
      newCard.isFaceDown = isFirstDealerCard
      hand.cards.push(newCard)
    },
    setIsDealing: (state, { payload: { isDealing } }) => {
      state.isDealing = isDealing
    },
    split: (state) => {
      const hands = state.hands.slice()
      hands[2] = clone(BASE_HAND)
      hands[2].cards.push(hands[1].cards.pop())
      hands[2].bets[0] = hands[1].bets[0]
      state.bank -= hands[2].bets[0]
      state.hands = hands
    },
    checkForBustsAndBlackjacks: (state) => {
      const hands = clone(state.hands)
      for (let i = 0; i < hands.length; i++) {
        const hand = hands[i]
        const total = blackjack.score(hand.cards)
        if (total > 21) hand.result = BUST
        if (total === 21 && hand.cards.length === 2) {
          hand.result = BLACKJACK
        }
        if (i > 0 && hands[0].result === BLACKJACK) {
          if (hand.result === BLACKJACK) hand.result = PUSH
          if (!hand.result) hand.result = LOSE
        }
      }
      state.hands = hands
    },
    compareHands: (state) => {
      console.log('compareHands')
      const hands = clone(state.hands)
      for (let i = 1; i < hands.length; i++) {
        const hand = hands[i]
        const total = blackjack.score(hand.cards)
        const dealerTotal = blackjack.score(hands[0].cards)
        if (dealerTotal === total) hand.result = PUSH
        if (dealerTotal > 21 && !hand.result) hand.result = WIN
        if (total > dealerTotal && !hand.result) hand.result = WIN
        if (dealerTotal > total && !hand.result) hand.result = LOSE
      }
      state.hands = hands
    },
    settleHands: (state) => {
      console.log(state)
      console.log('settleHands')
      const hands = clone(state.hands)
      for (let i = 1; i < hands.length; i++) {
        const hand = hands[i]
        if (hand.result === BLACKJACK) {
          hand.bets = Array(3).fill(hand.bets[0])
        }
        if (hand.result === WIN) hand.bets.push(...hand.bets)
        if ([LOSE, BUST].includes(hand.result)) hand.bets = []
      }
      state.hands = hands
    },
    collectWinnings: (state) => {
      console.log('collectWinnings')
      const hands = clone(state.hands)
      for (let i = 1; i < hands.length; i++) {
        const hand = hands[i]
        const winnings = hand.bets.reduce((a, b) => a + b, 0)
        state.bank += winnings
        hand.bets = []
      }
      state.hands = hands
    },
    revealDealerHand: (state) => {
      state.hands[0].cards[0].isFaceDown = false
    },
    advanceActiveHand: (state) => {
      console.log('advanceActiveHand - activeHandIndex', state.activeHandIndex)
      console.log('advanceActiveHand - hands.length', state.hands.length)
      if (state.activeHandIndex > 0) {
        state.activeHandIndex = state.activeHandIndex - 1
        console.log('new activeHandIndex : ', state.activeHandIndex - 1)
      }
      if (state.activeHandIndex === null) {
        state.activeHandIndex = state.hands.length - 1
        console.log('new hands : ', state.hands.length - 1)
      }
    },
    resetActiveHand: (state) => {
      console.log('resetActiveHand')
      state.activeHandIndex = null
    },
  },
})

export const selectActiveHand = (state) => {
  console.log(state.hands[state.activeHandIndex])
  return state.hands[state.activeHandIndex]
}

export const selectDealerTotal = (state) => {
  if (!state.hands.length) return
  return blackjack.score(state.hands[0].cards)
}

export const selectIsSplit = (state) => {
  return state.blackjack.hands.length > 2
}

export const selectCanSplit = (state) => {
  if (state.blackjack.bank < state.blackjack.settings.minimumBet) return false
  if (!state.blackjack.hands.length || !state.blackjack.activeHandIndex)
    return false
  if (state.blackjack.hands.length > 2) return false
  const cards = state.blackjack.hands[state.blackjack.activeHandIndex].cards
  return cards.length === 2 && cards[0].value === cards[1].value
}

export const selectCanDoubleDown = (state) => {
  if (state.blackjack.bank < state.blackjack.settings.minimumBet) return false
  if (!state.blackjack.hands.length || !state.blackjack.activeHandIndex)
    return false
  const cards = state.blackjack.hands[state.blackjack.activeHandIndex].cards
  return cards.length === 2
}

export const selectBasicStrategyMove = (state) => {
  if (
    !state.blackjack.hands.length ||
    !state.blackjack.activeHandIndex ||
    !state.blackjack.hands[state.blackjack.activeHandIndex]
  )
    return
  const playerCards =
    state.blackjack.hands[state.blackjack.activeHandIndex].cards
  const dealerCards = state.blackjack.hands[0].cards
  return blackjack.getBasicStrategyMove({ playerCards, dealerCards })
}

export const selectIsGameOver = (state) => {
  if (!state.blackjack.hands.length) return false
  const cards = state.blackjack.hands
    .map((hand) => hand.cards.length)
    .reduce((a, b) => a + b)
  const bets = state.blackjack.hands[1].bets.length
  return (
    state.blackjack.bank < state.blackjack.settings.minimumBet &&
    !bets &&
    !cards &&
    !state.blackjack.isDealing
  )
}

export default blackjackSlice.reducer
export const {
  hideTitleScreen,
  toggleDrawer,
  toggleBasicStrategy,
  resetShoe,
  resetBank,
  resetHands,
  bet,
  doubleBet,
  deal,
  setIsDealing,
  split,
  checkForBustsAndBlackjacks,
  compareHands,
  settleHands,
  collectWinnings,
  revealDealerHand,
  advanceActiveHand,
  resetActiveHand,
} = blackjackSlice.actions
