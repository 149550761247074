import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  getAddressFromApi,
  getBalance,
  getMnemonicFromApi,
  walletSetup,
  getwalletBal,
  getHistory,
} from '@/services/relysia-queries'

export const updateWalletDetails = createAsyncThunk(
  'wallet/updateWalletDetails',
  async (request, thunkAPI) => {
    try {
      const response = await axios.get(`/api/handcash/details`, {
        params: {
          authToken: request.authToken,
        },
      })

      if (response && !response?.error) return response
      else throw response?.error
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getInternalWalletBalance = createAsyncThunk(
  'wallet/getInternalWalletBalance',
  async (request, thunkAPI) => {
    try {
      const response = await getBalance()
      if (response?.data && !response.error) return response.data
      else throw response.error
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getMnemonic = createAsyncThunk(
  'wallet/getMnemonic',
  async (request, thunkAPI) => {
    try {
      const response = await getMnemonicFromApi()
      if (response?.data) return response.data
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getAddress = createAsyncThunk(
  'wallet/getAddress',
  async (request, thunkAPI) => {
    try {
      const response = await getAddressFromApi()
      if (!response.error && response?.data) return response.data
      return response
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const setupWallet = createAsyncThunk(
  'wallet/setupWallet',
  async (request, thunkAPI) => {
    try {
      const response = await walletSetup(request)
      if (response.data) {
        const { walletID } = response.data
        return walletID
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue('wallet ' + error.response.data.error)
      }
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const refreshWalletDetails = createAsyncThunk(
  'wallet/refreshWalletDetails',
  async (request, thunkAPI) => {
    try {
      const response = await getwalletBal()
      if (response) return response?.toFixed(4)
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const getHistoriesThunk = createAsyncThunk(
  'wallet/getHistoriesThunk',
  async (request, thunkAPI) => {
    try {
      const response = await getHistory(request)
      if (response?.data && !response.error) return response.data
      else throw response.error
    } catch (error) {
      console.log('error in getHistoriesThunk', error)
      return thunkAPI.rejectWithValue(error)
    }
  },
)

let internalWalletObj = {
  balanceBsv: '0',
  balanceUsd: '0',
  paymail: null,
  address: null,
  mnemonic: null,
  isPending: false,
  error: '',
  walletToken: '',
  walletId: '',
  histories: [],
  historyLoading: false,
  historyError: '',
  historyNextToken: '1',
}

const initialState = {
  handcash: {
    wallet: null,
    profile: null,
  },
  isPending: false,
  adminWallet: {
    address: '1PhAaCi1ohgWz5DteZzKGGaJgVgwZ5U6xb',
    paymail: '8615@relysia.com',
  },
  internalWallet: internalWalletObj,
  connectModal: false,
  handCashModal: false,
  depositModal: false,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    resetWalletDetails: (state) => {
      state.handcash = {
        wallet: null,
        profile: null,
      }
      state.internalWallet = internalWalletObj
    },
    updateInternalWalletBalance: (state, action) => {
      state.internalWallet = {
        ...state.internalWallet,
        balanceBsv: action.payload.balanceBsv,
        balanceUsd: action.payload.balanceUsd,
      }
    },
    handleConnectModal: (state, action) => {
      state.connectModal = action.payload
    },
    handleHandCashModal: (state, action) => {
      state.handCashModal = action.payload
    },
    handleDepositModal: (state, action) => {
      state.depositModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateWalletDetails.fulfilled, (state, action) => {
        const { profile, wallet } = action.payload.data
        state.handcash = {
          wallet,
          profile,
        }
        state.isPending = false
      })
      .addCase(updateWalletDetails.pending, (state, action) => {
        state.isPending = true
      })
      .addCase(updateWalletDetails.rejected, (state, action) => {
        state.isPending = false
        state.handcash = {
          wallet: null,
          profile: null,
        }
      })
      .addCase(getInternalWalletBalance.fulfilled, (state, action) => {
        const { balanceUsd, balanceBSV } = action.payload
        state.internalWallet.isPending = false
        state.internalWallet.balanceBsv = balanceBSV
        state.internalWallet.balanceUsd = balanceUsd
      })
      .addCase(getInternalWalletBalance.pending, (state, action) => {
        state.internalWallet.isPending = true
      })
      .addCase(getInternalWalletBalance.rejected, (state, action) => {
        state.internalWallet.isPending = false
        state.internalWallet.error = action.payload
      })
      .addCase(getAddress.fulfilled, (state, action) => {
        const { address, paymail } = action.payload
        state.internalWallet.isPending = false
        state.internalWallet.paymail = paymail
        state.internalWallet.address = address
      })
      .addCase(getAddress.pending, (state, action) => {
        state.internalWallet.isPending = true
      })
      .addCase(getAddress.rejected, (state, action) => {
        state.internalWallet.isPending = false
        state.internalWallet.error = action.payload
      })
      .addCase(setupWallet.fulfilled, (state, action) => {
        state.internalWallet.walletId = action.payload
        state.internalWallet.isPending = false
      })
      .addCase(setupWallet.pending, (state, action) => {
        state.internalWallet.isPending = true
      })
      .addCase(setupWallet.rejected, (state, action) => {
        state.internalWallet.isPending = false
        state.internalWallet.error = action.payload
      })
      .addCase(getMnemonic.fulfilled, (state, action) => {
        state.internalWallet.mnemonic = action.payload
        state.internalWallet.isPending = false
      })
      .addCase(refreshWalletDetails.fulfilled, (state, action) => {
        state.internalWallet.balanceBsv = action.payload
        state.internalWallet.isPending = false
      })
      .addCase(refreshWalletDetails.pending, (state, action) => {
        state.internalWallet.isPending = true
      })
      .addCase(refreshWalletDetails.rejected, (state, action) => {
        state.internalWallet.isPending = false
        state.internalWallet.error = action.payload
      })
      .addCase(getHistoriesThunk.fulfilled, (state, action) => {
        const prev = [...state.internalWallet.histories] || []
        const historyData = action.payload.histories
        const uniques = historyData.filter((history) => {
          return !prev.some((prevHist) => prevHist.txId === history.txId) || []
        })
        const nextToken = action?.payload?.meta?.nextPageToken
          ? action?.payload?.meta?.nextPageToken
          : null
        state.internalWallet.histories = [...prev, ...uniques]
        state.internalWallet.historyNextToken = nextToken
        state.internalWallet.historyLoading = false
      })
      .addCase(getHistoriesThunk.pending, (state, action) => {
        state.internalWallet.historyLoading = true
      })
      .addCase(getHistoriesThunk.rejected, (state, action) => {
        state.internalWallet.historyLoading = false
        state.internalWallet.historyError = action.payload
      })
  },
})

export default walletSlice.reducer
export const {
  resetWalletDetails,
  updateInternalWalletBalance,
  handleConnectModal,
  handleHandCashModal,
  handleDepositModal,
} = walletSlice.actions
