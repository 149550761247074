import { configureStore, combineReducers } from '@reduxjs/toolkit'
import authSlice from '../slices/auth'
import betsSlice from '../slices/bets'
import sidebarSlice from '../slices/sidebar'
import sportsSlice from '../slices/sports'
import walletSlice from '../slices/wallet'
import betSlipsSlice from '../slices/betSlips'
import plinkoSlice from '../slices/plinko'
import favouritesSlice from '../slices/favourites'
import leaderBoardSlice from '../slices/leaderBoard'
import blackJackSlice from '../slices/blackJack'

const reducer = combineReducers({
  // Add your reducers here
  auth: authSlice,
  wallet: walletSlice,
  sports: sportsSlice,
  sidebar: sidebarSlice,
  bets: betsSlice,
  betSlips: betSlipsSlice,
  plinko: plinkoSlice,
  favourites: favouritesSlice,
  leaderBoard: leaderBoardSlice,
  blackjack: blackJackSlice,
})

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store
