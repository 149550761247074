import { createDraftSafeSelector } from '@reduxjs/toolkit'

const selectSidebar = (state) => state.sidebar

const sidebarSelector = createDraftSafeSelector(
  selectSidebar,
  (sidebar) => sidebar,
)

export default sidebarSelector
