export const GAMES_COLLECTION_NAME = 'new-games-concept'
export const SIDEBAR_COLLECTION_NAME = 'new-sidebar-data'

export const BETS_COLLECTION_NAME = process.env.NEXT_PUBLIC_BETS_COLLECTION_NAME

export const CASINO_COLLECTION_NAME =
  process.env.NEXT_PUBLIC_CASINO_COLLECTION_NAME

export const REFERRER_COLLECTION_NAME =
  process.env.NEXT_PUBLIC_REFERRER_COLLECTION_NAME
