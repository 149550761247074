import { createDraftSafeSelector } from '@reduxjs/toolkit'

const selectBetSlips = (state) => state.betSlips

const betSlipsSelector = createDraftSafeSelector(
  selectBetSlips,
  (betSlips) => betSlips,
)

export default betSlipsSelector
