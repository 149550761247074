import NextImage from '@/utils/Image'
import { contentStyle, contentTransitionStyles } from '@/utils/transition'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { Transition } from 'react-transition-group'
import '../../../styles/Home.module.css'
import betSlipsSelector from '@/redux/selectors/betSlips'
import { useMediaQuery } from 'react-responsive'
import { FormattedMessage } from 'react-intl'

// const list = [
//   { name: 'Casino', to: '/casino/plinko' },
//   { name: 'Sports', to: '/sports/popular-events' },
//   { name: 'Leaderboard', to: '/leaderboard' },
//   // { name: 'Blog', to: '/resource' },
//   { name: 'Contact', to: '/help' },
//   { name: 'FAQ', to: '/faqs' },
//   { name: 'Privacy', to: '/privacy-policy' },
// ]
const list = [
  { nameKey: 'footer.casino', defaultName: 'Casino', to: '/casino/plinko' },
  {
    nameKey: 'footer.sports',
    defaultName: 'Sports',
    to: '/sports/popular-events',
  },
  {
    nameKey: 'footer.leaderboard',
    defaultName: 'Leaderboard',
    to: '/leaderboard',
  },
  // { nameKey: 'footer.blog', defaultName: 'Blog', to: '/resource' },  // Commented out as before
  { nameKey: 'footer.contact', defaultName: 'Contact', to: '/help' },
  { nameKey: 'footer.faq', defaultName: 'FAQ', to: '/faqs' }, // Assuming you'll have a translation key for FAQ
  { nameKey: 'footer.privacy', defaultName: 'Privacy', to: '/privacy-policy' },
]

const footerIcon = [
  {
    // src: '/img/twitter.webp',
    src: '/img/twitternewlogo.png',
    alt: 'Twitter link icon',
    href: 'https://twitter.com/lilbitgame',
    width: 24,
    height: 20,
  },
  {
    src: '/img/insta-logo.svg',
    alt: 'Insta link icon',
    href: 'https://www.instagram.com/lilbitgame/',
    width: 24,
    height: 24,
  },
  {
    src: '/svgs/TwetchIcon.svg',
    alt: 'Twetch link icon',
    href: 'https://twetch.com/u/63',
    width: 24,
    height: 24,
  },
]

export default function Footer({
  isOpen,
  width,
  conditionalFooterWidth,
  isHome,
}) {
  const { betslipsOpen } = useSelector(betSlipsSelector)
  const sm = useMediaQuery({ query: '(max-width: 639px)' })

  return (
    <Transition in={isOpen} timeout={300}>
      {(state) => (
        <div
          style={{
            ...contentStyle(300),
            ...contentTransitionStyles[state],
          }}
          className={`${width} ml-auto lg:text-left text-center sm:pl-8 ${
            isHome ? 'md:pr-4 lg:pr-16' : 'sm:pr-8'
          } overflow-x-hidden`}
        >
          <div
            className={`${conditionalFooterWidth} lg:flex md:justify-between text-[#EAECF0] text-base pt-8 sm:pt-0`}
          >
            <div className="flex-col">
              <div className="pb-12">
                <ul className="md:flex justify-center flex-wrap gap-x-8">
                  {list.map((value, index) => {
                    return (
                      <li
                        key={index + 'linkList'}
                        className="font-medium hover:text-white/70 "
                      >
                        {/* <Link href={value.to}>{value.name}</Link> */}
                        <Link href={value.to}>
                          <FormattedMessage
                            id={value.nameKey}
                            defaultMessage={value.defaultName}
                          />
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className={`${conditionalFooterWidth} pb-7`}>
            <hr className="text-[#EAECF0] px-5"></hr>
          </div>
          <div
            className={`${conditionalFooterWidth} md:flex mb-[70px] sm:mb-0 justify-between text-[#EAECF0] text-base pb-7`}
          >
            <div>
              <p className="text-base text-[#98a2b3]">
                © {new Date().getFullYear()}{' '}
                <FormattedMessage
                  id="footer.copyright"
                  defaultMessage="Lilb.it. All rights reserved."
                />
              </p>
            </div>
            <div
              className={`flex justify-center md:mt-0 mt-4 items-center gap-x-2 relative mb-12 sm:mb-0 ${
                sm && betslipsOpen && 'hidden'
              }`}
            >
              {footerIcon.map((value, index) => {
                return (
                  <a href={value.href} target="_blank" key={index}>
                    <NextImage
                      width={37}
                      height={37}
                      key={index}
                      src={value.src}
                      alt={value.alt}
                      className="pl-3 hover:opacity-80 cursor-pointer"
                    />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </Transition>
  )
}
